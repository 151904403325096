import { formatDistanceToNow } from 'date-fns';
import { es } from 'date-fns/locale';
import { autoserializeAs, autoserializeAsArray, onDeserialized } from 'dcerialize';

import { dateToString } from '../utils/date-transforms';

/**
 * Review CRUD class
 */
export class Review {
  /**
   * ID
   */
  @autoserializeAs(() => Number) id: number;

  /**
   * Review creation date
   */
  @autoserializeAs(() => Date, 'created_at') createdAt: Date;

  /**
   * Review mean score
   */
  @autoserializeAs(() => Number, 'mean_score') meanScore: number;

  /**
   * Review comment
   */
  @autoserializeAs(() => String, 'review_comment') commentReview: string;

  /**
   * Reservation ID
   */
  @autoserializeAs(() => Number, 'reservation_id') reservationID: number;

  /**
   * Full name of the reviewer
   */
  @autoserializeAs(() => String, 'client_name') clientName: string;

  /**
   * Business name of the operator
   */
  @autoserializeAs(() => String, 'operator_name') operatorName?: string;

  /**
   * Route of the reservation
   */
  @autoserializeAs(() => String, 'reservation_route') reservationRoute?: string;

  /**
   * Start date of the reservation
   */
  @autoserializeAs(() => Date, 'reservation_start_date') reservationStartDate: Date;

  /**
   * End date of the reservation
   */
  @autoserializeAs(() => Date, 'reservation_end_date') reservationEndDate: Date;

  /**
   * Review scores
   */
  @autoserializeAs(() => Number, 'service_score') serviceScore: number;
  @autoserializeAs(() => Number, 'driver_score') driverScore: number;
  @autoserializeAs(() => Number, 'vehicle_clean_score') vehicleCleanScore: number;
  @autoserializeAs(() => Number, 'plan_quality_score') planQualityScore: number;
  @autoserializeAs(() => Number, 'operator_communication_score') operatorCommunicationScore: number;

  constructor(
    id: number,
    createdAt: Date,
    meanScore: number,
    commentReview: string,
    reservationID: number,
    serviceScore: number,
    driverScore: number,
    vehicleCleanScore: number,
    planQualityScore: number,
    operatorCommunicationScore: number,
    reservationStartDate: Date,
    reservationEndDate: Date,
    clientName: string,
    operatorName?: string,
    reservationRoute?: string
  ) {
    this.id = id;
    this.createdAt = createdAt;
    this.meanScore = meanScore;
    this.commentReview = commentReview;
    this.reservationID = reservationID;
    this.clientName = clientName;
    this.operatorName = operatorName;
    this.reservationRoute = reservationRoute;
    this.serviceScore = serviceScore;
    this.driverScore = driverScore;
    this.vehicleCleanScore = vehicleCleanScore;
    this.planQualityScore = planQualityScore;
    this.operatorCommunicationScore = operatorCommunicationScore;
    this.reservationStartDate = reservationStartDate;
    this.reservationEndDate = reservationEndDate;
  }

  @onDeserialized
  formatMeanScore(): void {
    this.meanScore.toFixed(1);
  }

  @onDeserialized
  calculateTimeAgo(): string {
    if (this.createdAt === undefined) {
      return '';
    }

    const distance = formatDistanceToNow(this.createdAt, { addSuffix: true, locale: es });

    return distance.charAt(0).toUpperCase() + distance.slice(1);
  }

  /**
   * Formats the starting and ending date of the reservation
   *
   * @return A string formatted as <start-day> <start-month (if different)> - <end-day> <end-month>
   */
  formatTripDates(): string {
    const startDayStr = dateToString(this.reservationStartDate, 'dd');
    const startMonthStr = this.reservationStartDate.toLocaleString('es-ES', { month: 'long' });

    const endDayStr = dateToString(this.reservationEndDate, 'dd');
    const endMonthStr = this.reservationEndDate.toLocaleString('es-ES', { month: 'long' });

    if (startMonthStr === endMonthStr) {
      return `${startDayStr} - ${endDayStr} ${endMonthStr}`;
    } else {
      return `${startDayStr} ${startMonthStr} - ${endDayStr} ${endMonthStr}`;
    }
  }
}

/**
 * List of Review object
 */
export class ReviewList {
  @autoserializeAsArray(() => Review) items: Review[];
  @autoserializeAs(() => Number) total: number;

  constructor(items: Review[] = [], total = 0) {
    this.items = items;
    this.total = total;
  }
}
